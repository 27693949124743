<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row class="center mt-6">
      <v-col lg="6" xl="4" class="padding forgot-password">
        <v-card class="elevation-4">
          <div class="pa-2 pa-sm-6">
            <h2
              class="font-weight-bold mt-4 blue-grey--text text--darken-2 title"
            >
              Reset Password
            </h2>
            <h6 class="subtitle-1">
              Enter new password
            </h6>

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="password"
                :counter="10"
                hide-details="auto"
                :rules="passwordRules"
                label="Password"
                required
                outlined
                class="mt-4"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
              ></v-text-field>
              <v-btn
                :disabled="!valid"
                color="info"
                block
                class="mt-6"
                submit
                @click="submit"
                >Reset Password</v-btn
              >
            </v-form>
          </div>
        </v-card>
      </v-col>
      <v-snackbar v-model="snackbar">
        {{ notification }}
        <template v-slot:action="{ attrs }">
          <v-btn color="#fc4b6c" text v-bind="attrs" @click="snackbar = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ResetPassword",
  data: () => ({
    valid: false,
    show1: false,
    password: "",
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length <= 10) || "Password must be less than 10 characters"
    ],
    notification: "",
    snackbar: false
  }),
  computed: {
    ...mapGetters(["isAdmin", "isClientAdmin", "isClientUser", "isDriver"])
  },
  methods: {
    submit() {
      if (this.$refs.form.validate(true)) {
        this.$store
          .dispatch("auth/resetPassword", {
            code: this.$route.query.code,
            password: this.password
          })
          .then(response => {
            this.notification = "Successfull";
            this.snackbar = true;
            setTimeout(() => {
              this.$router.push({ name: "Login" });
            }, 3000);
          })
          .catch(err => {
            console.error(err);
            this.notification = "Error";
            this.snackbar = true;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.forgot-password {
  margin: 0 auto;
}

.img {
  width: 100%;
  max-width: 140px;

  @media screen and (max-height: 850px) {
    display: none;
  }
}

.myrow {
  display: flex;
  flex-direction: row;
}

.main-container {
  @media screen and (max-width: 959px) {
    flex-direction: column !important;
  }
}

.mycontainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.col {
  @media screen and (max-width: 1264px) {
    padding-top: 0 !important;
  }
}

.upload-btns {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  flex-direction: column;
}

.myinput {
  margin-right: 10px;
}

.myrow {
  display: flex;
}

.padding {
  padding: 0 55px;

  @media screen and (max-width: 485px) {
    padding: 0 15px;
  }
}

.title {
  @media screen and (max-height: 850px) {
    margin-top: 0 !important;
  }
}
</style>
